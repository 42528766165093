import logo from "./logo.svg";
import "./App.css";
import "./styles/remixicon.css";
import "react-tabs/style/react-tabs.css";
import "swiper/css";
import "swiper/css/bundle";

// Chat Styles
import "./styles/chat.css";
// Globals Styles
import "./styles/globals.css";
// Rtl Styles
import "./styles/rtl.css";
// Dark Mode Styles
import "./styles/dark.css";
// Left Sidebar Dark Mode Styles
import "./styles/leftSidebarDark.css";
// Theme Styles
import theme from "./styles/theme";

import { ThemeProvider, CssBaseline } from "@mui/material";
import { Routes, Route } from "react-router-dom";



// import Authentication from "./modules/AuthenticationModule";
// import Layout from "./modules/LayoutModule";
// import Dashboard from "./modules/DashboardModule";
// import Masters from "./modules/MastersModule";
// import Subscriptions from "./modules/SubscriptionsModule";
// import Distributors from "./modules/DistributorsModule";
// import Producers from "./modules/ProducersModule";
// import Advertisers from "./modules/AdvertisersModule";
// import Customer from "./modules/CustomerModule";
// import WatchHours from "./modules/WatchhoursModule";
// import Transaction from "./modules/TransactionModule";
// import Complaint from "./modules/ComplaintModule";
// import LiveStreaming from "./modules/LiveStreamingModule";
// import Report from "./modules/ReportModule";
// import AdForm from "./modules/AdFormModule";
// import Notification from "./modules/NotificationModule";
// import Coupon from "./modules/CouponModule";
// import HomeSlider from "./modules/HomeSliderModule";
// import BankDetails from "./modules/BankDetailsModule"
// import Movie from "./modules/MoviesModule";
// import WebSeries from "./modules/WebSeriesModule";
// import AdvertiserElements from "./modules/AdvertiserElementsModule";
// import Promotion from "./modules/PromotionModule";
// import CollaboratorForm from "./modules/CollectionFormModule";
// import Episode from "./modules/EpisodeModule";
// import Season from "./modules/SeasonModule";
// import Series from "./modules/SeriesModule";
// import AdTransaction from "./modules/AdTransactionModule";
// import ProducerForm from "./components/ProducerForm/ProducerForm";
// import ThankYouMsg from "./components/Thankyu/Thank";
// import SetMovieAdvertisement from "./modules/SetMovieAdvertisementModule"
// import SetSeriesAdvertisement from "./modules/SetSeriesAdvertisementModule"
// import LiveMovieView from "./modules/LiveMovieViewModule"
// import DistributorsFormData from "./modules/DistributorsFormDataModule";
// import Settings from "./modules/SettingModule";
// import Reports from "./modules/ReportsModule";
// import PagenotFound from "./components/PageNotFound/PageNotFound";




import { login } from "./actions/authenticate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import React, { Suspense, useEffect } from 'react';
import {
  PROFILE,
  ADVERTISER,
  DISTRIBUTOR,
  RIGHTS,
  ROLE,
  LOGGEDIN,
} from "./constants/actionTypes";
import Loader from './components/Loader/loader.js'
const Authentication = React.lazy(() => import('./modules/AuthenticationModule'));
const Layout = React.lazy(() => import('./modules/LayoutModule'));
const Dashboard = React.lazy(() => import('./modules/DashboardModule'));
const Masters = React.lazy(() => import('./modules/MastersModule'));
const Subscriptions = React.lazy(() => import('./modules/SubscriptionsModule'));
const Distributors = React.lazy(() => import('./modules/DistributorsModule'));
const Producers = React.lazy(() => import('./modules/ProducersModule'));
const Advertisers = React.lazy(() => import('./modules/AdvertisersModule'));
const Customer = React.lazy(() => import('./modules/CustomerModule'));
const WatchHours = React.lazy(() => import('./modules/WatchhoursModule'));
const Transaction = React.lazy(() => import('./modules/TransactionModule'));
const Complaint = React.lazy(() => import('./modules/ComplaintModule'));
const LiveStreaming = React.lazy(() => import('./modules/LiveStreamingModule'));
const Report = React.lazy(() => import('./modules/ReportModule'));
const AdForm = React.lazy(() => import('./modules/AdFormModule'));
const Notification = React.lazy(() => import('./modules/NotificationModule'));
// const Coupon = React.lazy(() => import('./modules/CouponModule'));
const HomeSlider = React.lazy(() => import('./modules/HomeSliderModule'));
const BankDetails = React.lazy(() => import('./modules/BankDetailsModule'));
const Movie = React.lazy(() => import('./modules/MoviesModule'));
const WebSeries = React.lazy(() => import('./modules/WebSeriesModule'));
const AdvertiserElements = React.lazy(() => import('./modules/AdvertiserElementsModule'));
const Promotion = React.lazy(() => import('./modules/PromotionModule'));
const CollaboratorForm = React.lazy(() => import('./modules/CollectionFormModule'));
const Episode = React.lazy(() => import('./modules/EpisodeModule'));
const Season = React.lazy(() => import('./modules/SeasonModule'));
const Series = React.lazy(() => import('./modules/SeriesModule'));
const Payment = React.lazy(() => import('./modules/PaymentModule.js'));
const Highlight = React.lazy(()=> import('./modules/HighlightModule.js'))
const AdTransaction = React.lazy(() => import('./modules/AdTransactionModule'));
const ProducerForm = React.lazy(() => import('./components/ProducerForm/ProducerForm'));
const ThankYouMsg = React.lazy(() => import('./components/Thankyu/Thank'));
const SetMovieAdvertisement = React.lazy(() => import('./modules/SetMovieAdvertisementModule'));
const SetSeriesAdvertisement = React.lazy(() => import('./modules/SetSeriesAdvertisementModule'));
const LiveMovieView = React.lazy(() => import('./modules/LiveMovieViewModule'));
const DistributorsFormData = React.lazy(() => import('./modules/DistributorsFormDataModule'));
const Settings = React.lazy(() => import('./modules/SettingModule'));
const Reports = React.lazy(() => import('./modules/ReportsModule'));
const PagenotFound = React.lazy(() => import('./components/PageNotFound/PageNotFound'));
const OnDemand = React.lazy(()=> import('./modules/OnDemandModule.js'))

const Analytics= React.lazy(() => import('./modules/AnalyticsModule'));

// import PageNotFound from "./components/PageNotFound/PageNotFound";
function App() {
  const isLoggedIn = useSelector((state) => state.layout.loggedin);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = JSON.parse(
    localStorage.getItem("loggedInDetails") == undefined
      ? "{}"
      : localStorage.getItem("loggedInDetails")
  );
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("remember_me")) == true &&
      location.pathname == "/"
    ) {
      dispatch(
        login(JSON.parse(localStorage.getItem("loginDetails")), navigate)
      );
    } else if (Object.keys(data).length !== 0) {
      dispatch({ type: PROFILE, payload: data?.id });
      dispatch({ type: ADVERTISER, payload: data?.advertiser?.[0] });
      dispatch({ type: DISTRIBUTOR, payload: data?.distributor?.[0] });
      dispatch({ type: RIGHTS, payload: data?.Rights?.[0] });
      dispatch({ type: ROLE, payload: data?.id?.userType?.roleName });
      dispatch({ type: LOGGEDIN, payload: true });
    } else {
      localStorage.setItem("loggedInDetails", "{}");
    }
  }, []);
  // useEffect(() => {

  //   const handleContextMenu = (e) => {

  //     e.preventDefault()
  //   }

  //   document.addEventListener("contextmenu", handleContextMenu)

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu)
  //   }
  // }, [])
  // document.onkeydown = function(e) {
  //   if(e.keyCode == 123) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
  //      return false;
  //   }
  // }
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<Loader/>}>
        <Routes>
          {/* Test */}
          {/* < Route path='/*' element={isLoggedIn==true?<Dashboard key="Dashboard"/>:<Authentication key="Authentication"/>}/> */}
          {/* test */}
          <Route
            path="/Authentication/*"
            element={<Authentication key="Authentication" />}
          />
          <Route path="/Layout/*" element={<Layout key="Layout" />} />
          <Route path="/Dashboard/*" element={<Dashboard key="Dashboard" />} />
          <Route path="/Analytics/*" element={<Analytics key="Analytics" />} />
          <Route path="/Masters/*" element={<Masters key="Masters" />} />
          <Route path="/Customer/*" element={<Customer key="Customer" />} />
          <Route path="/OnDemand/*" element={<OnDemand key="OnDemand" />} />
          <Route
            path="/Subscriptions/*"
            element={<Subscriptions key="Subscriptions" />}
          />
          <Route
            path="/Distributors/*"
            element={<Distributors key="Distributors" />}
          />
          <Route
            path="/Producers/*"
            element={<Producers key="Producers" />}
          />
          <Route
            path="/Advertisers/*"
            element={<Advertisers key="Advertisers" />}
          />
          <Route
            path="/WatchHours/*"
            element={<WatchHours key="WatchHours" />}
          />
          <Route path="/AdForm/*" element={<AdForm key="AdForm" />} />
          <Route path="/Payment/*" element={<Payment key="Payment"/>}/>
          <Route path="/Complaint/*" element={<Complaint key="Complaint" />} />
          <Route
            path="/Transactions/*"
            element={<Transaction key="Transaction" />}
          />
          <Route
            path="/LiveStreaming/*"
            element={<LiveStreaming key="LiveStreaming" />}
          />
            <Route
            path="/BankDetails/*"
            element={<BankDetails key="BankDetails" />}
          />
           <Route
            path="/LiveMovieView/*"
            element={<LiveMovieView key="LiveMovieView" />}
          />
          <Route path="/Report/*" element={<Report key="Report" />} />
          <Route path="/Highlight/*" element={<Highlight key="Highlight" />}/>
          <Route path="/reports/*" element={<Reports key="reports" />} />
          <Route
            path="/Promotion/*"
            element={<Promotion key="PromotionModule" />}
          />
          <Route
            path="/Notifications/*"
            element={<Notification key="Notification" />}
          />

          {/* <Route path="/Coupon/*" element={<Coupon key="Coupon" />} /> */}
          <Route
            path="/SliderBanner/*"
            element={<HomeSlider key="HomeSlider" />}
          />
          <Route path="/Movie/*" element={<Movie key="Movie" />} />
          <Route path="/WebSeries/*" element={<WebSeries key="WebSeries" />} />
          <Route path="/Settings/*" element={<Settings key="Settings" />} />
          <Route
            path="/ProducerForm/"
            element={<ProducerForm key="ProducerForm" />}
          />
          <Route
            path="/CollaboratorForm/*"
            element={<CollaboratorForm key="CollaboratorForm" />}
          />
          <Route path="/Episode/*" element={<Episode key="Episode" />} />
          <Route path="/Season/*" element={<Season key="Season" />} />
          <Route path="/Series/*" element={<Series key="Series" />} />
          <Route path="/SetMovieAdvertisement/*" element={<SetMovieAdvertisement key="SetMovieAdvertisement" />}/>
          <Route path="/SetSeriesAdvertisement/*" element={<SetSeriesAdvertisement key="SetSeriesAdvertisement" />}/>


          <Route
            path="/AdTransaction/*"
            element={<AdTransaction key="Transaction" />}
          />
          <Route
            path="/ProducerForm/Success"
            element={<ThankYouMsg key="ThankYou" />}
          />
          <Route
            path="/DistributorsFormData/*"
            element={<DistributorsFormData key="DistributorsFormData" />}
          />
          <Route
            path="/AdvertiserElements/*"
            element={<AdvertiserElements key="AdvertiserElements" />}
          />
          <Route path="/*" element={<Authentication key="Authentication" />} />

          {/* <Route  Component={<PagenotFound/>}/> */}
        </Routes>     </Suspense>
      </ThemeProvider>
    </div>
  );
}

export default App;
